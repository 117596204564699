


















































































































































































































































































import { computed, defineComponent } from "@vue/composition-api";

import { ValidationObserver } from "vee-validate";
import { IPayment } from "@/interface/payment";
export default defineComponent({
  props: {
    loading: Boolean
  },
  setup(props, { root, refs, emit }) {
    const state = {
      enabled: false,
      // radioGroup: 1,
      fname: "",
      lname: "",
      address1: "",
      address2: "",
      address3: "",
      telephone: 0,
      email: "",
      amount: 0,
      remarks: "",
      authorization: "agree"
    };

    const logo = computed(() => require("@/assets/img/visa_master_small.png"));
    const remarkslist = computed(() => [
      {
        paragraph: root.$t("remarks-paragraph-1")
      },
      {
        paragraph: root.$t("remarks-paragraph-2")
      }
    ]);
    const prefixingName = computed(() => [
      {
        title: root.$t("Mr")
      },
      {
        title: root.$t("Ms")
      },
      {
        title: root.$t("Mrs")
      }
    ]);

    const veeDictionary = {
      custom: {
        name: {
          required: () => "Name can not be empty",
          max: "The name field may not be greater than 64 characters"
          // custom messages
        },
        amount: {
          required: "Amount field is required"
        }
      }
    };

    async function handleConfirmPayment() {
      const isValid = await (refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (isValid) {
        confirmPayment();
      }
    }

    function confirmPayment() {
      emit("update:loading", false);

      const params: IPayment = {} as IPayment;
      params.fname = state.fname;
      params.lname = state.lname;
      params.address1 = state.address1;
      params.address2 = state.address2;
      params.address3 = state.address3;
      params.telephone = state.telephone;
      params.email = state.email;
      params.amount = state.amount;
      params.remarks = state.remarks;
      emit("onConfirmPayment", params);
      return params;
    }

    return { state, handleConfirmPayment, veeDictionary, prefixingName, remarkslist, logo };
  }
});
